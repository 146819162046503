import * as React from "react";
import type { HeadFC } from "gatsby";
import { Seo } from "components/Seo";
import { Layout } from "components/Layout";

const TermsOfService: React.FC = () => {
  return (
    <Layout>
      <h1 className="text-3xl font-bold underline">TermsOfService</h1>
    </Layout>
  );
};

export default TermsOfService;

export const Head: HeadFC = () => <Seo title="利用規約" />;
